// New variables declared only in this file must also be declared in each tenant's _variables.scss

$mdc-theme-primary: #3D83E1;
$mdc-theme-primary-light: #639BE7;
$mdc-theme-primary-dark: #4475B7;
$mdc-theme-secondary: #3D83E1;
$mdc-theme-secondary-light: #639BE7;
$mdc-theme-secondary-dark: #4475B7;
$mdc-theme-background: #f3f5f9;

$mdc-layout-grid-breakpoints: (
        desktop: 1026px,
        tablet: 480px,
        phone: 0px
);

// Flexperto custom properties
$flx-border-radius: 8px;
$flx-border-plain: #eaeaea;
$flx-border-primary: $mdc-theme-primary;
$flx-theme-success: #6ECC94;
$flx-theme-danger: #ff4858;
$flx-theme-warning: #FFD74D;
$flx-theme-text: #57626B;
$flx-theme-background: #F7F8FA;

$flx-theme-phone-accept: #4CD964;
$flx-theme-phone-decline: #F53333;
$flx-theme-phone-message: $flx-theme-text;

/**
 * DESIGN SYSTEM
 *
 * Refer to https: //www.figma.com/file/cLm3zedW6uL7fTTqqfoXJb6g/Design-System?node-id=2257%3A10118
*/

$white: #ffffff;

$blue-100: #117CE0;
$blue-80: #3A9EFB;
$blue-60: #61B1FC;
$blue-40: #B0D8FD;
$blue-20: #D8ECFE;
$blue-5: #F5FAFF;

$dark-100: #05162F;
$dark-80: #071C3B;
$dark-60: #263854;
$dark-40: #45556C;
$dark-20: #647184;
$dark-5: #838E9D;

$light-100: #9CA5B1;
$light-80: #ABB2BC;
$light-60: #C1C6CE;
$light-40: #E6E8EB;
$light-20: #F3F5F9;
$light-5: #F7F8FB;

$green-100: #457B3B;
$green-80: #5D9E52;
$green-60: #7BBA72;
$green-40: #AFD5AB;
$green-20: #CEE4CB;
$green-5: #EAF5EA;

$amber-100: #ED782F;
$amber-80: #F2A43A;
$amber-60: #F7CC50;
$amber-40: #FBE18F;
$amber-20: #FCEDBA;
$amber-5: #FEF8E3;

$red-100: #CB3E20;
$red-80: #EB4F27;
$red-60: #EE7950;
$red-40: #F1A286;
$red-20: #F7CEBF;
$red-5: #F8EAE7;